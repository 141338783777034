<template>
  <b-card>
    <base-info
      :geturl="dataurl"
      :fields="fields"
      :baseroute="baseroute"
      title-icon="UsersIcon"
      title="Detail Info"
      :isdelete="true"
      :isedit="false"
      :is-action="true"
    >
      <template v-slot:buttonaction>
        <b-button
          class="mr-1"
          variant="primary"
          size="sm"
          type="button"
          @click.prevent="getEdit"
        >
          
          <span>Edit</span>
        </b-button>
      </template>
    </base-info>
  </b-card>
</template>

<script>

import {BCard,BButton} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'

export default {
  components: {
    BCard,
    BButton,
    BaseInfo
  },

  data() {
    return {
      dataurl:"/user",
      baseroute:"user",
      fields: [
        { key: 'email', label: 'Username', bold:true},
        { key: 'name', label: 'Nama' },
        { key: 'level', label: 'Level'},
        { key: 'region', label: 'Region'},
        { key: 'last_login', label: 'Last Login'},
        { key: 'is_active', label: 'Status', type:'span', span:{true:'Aktif', false:'Tidak Aktif'}}
      ],
    }
  },
  methods:{
    getEdit(){
      this.$router.push({name:'data-perusahaan-user-sub-edit',params:{id:this.$route.params.id}})
    },
    getHapus(){
      this.$router.push({name:'data-perusahaan-user-sub-edit',params:{id:this.$route.params.id}})
    },
  }
}
</script>
